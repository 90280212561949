var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-row',{staticClass:"mx-4",attrs:{"justify":"center"}},_vm._l((_vm.transactions),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('base-material-stats-card',{staticClass:"toggle-item",attrs:{"color":item.color,"icon":item.icon,"title":"Transaccion","top":item.title,"to":item.to}})],1)}),1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"warning"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"text-h3 font-weight-light"},[_vm._v(" Inventario ")]),_c('div',{staticClass:"text-text-subtitle-1 font-weight-light"},[_vm._v(" Inventario disponible ")]),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"warning","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" Reporte   "),_c('v-icon',{attrs:{"right":"","dark":"","size":"25"}},[_vm._v(" mdi-printer ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('h3',[_vm._v(" Seleccione el campo del inventario que le gustraia reportar ")])]),_c('br'),_c('br'),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-autocomplete',{attrs:{"items":_vm.typesOfInventory,"outlined":"","label":"Campos de inventario"},model:{value:(_vm.typeReport),callback:function ($$v) {_vm.typeReport=$$v},expression:"typeReport"}})],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.genPdf}},[_vm._v(" Generar ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")])],1)],1)]}}])})],1)],1)]},proxy:true}])},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busca por cualquier campo","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.inventory,"loading":_vm.fechingWarehouseInventory,"search":_vm.search,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
            'items-per-page-all-text': 'Todos',
            'items-per-page-options': [
              100,
              -1
            ],
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"item.bigWholesalePrices",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.bigWholesalePrices))+" ")]}},{key:"item.total",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.bigWholesalePrices * item.quantity_total))+" ")]}},{key:"item.available",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity_packing_available)+" / "+_vm._s(item.quantity_available)+" ")]}},{key:"item.concession",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity_packing_concession)+" / "+_vm._s(item.quantity_concession)+" ")]}},{key:"item.units",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.packingUnit)+" / "+_vm._s(item.salesUnit)+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"warning--text"},[_c('th',{staticClass:"text-center text-h4"},[_vm._v(" Totales ")]),_c('th'),_vm._l((_vm.totalOfInventory),function(item,i){return _c('th',{key:i,staticClass:"text-center text-h4"},[(i === _vm.totalOfInventory.length - 1)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item))+" ")]):_c('span',[_vm._v(_vm._s(item))])])})],2)])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }